import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Container, Row } from "react-bootstrap";
import Header from 'components/Navbars/AdminNavbar';
import BookingInfo from './BookingInfo';
import '../assets/css/demo.css';
import useBookingRequestDetailsApi from 'hooks/useBookingRequestDetailsApi';


const BookingRequestDetailsPage = () => {

    const params = useParams();
    const history = useHistory();
    const url = `/admin/bookingRequests/${params.id}`;
    const { currentUser, isLoading } = useBookingRequestDetailsApi(url);

    const backButton = () => {
        history.push('/admin/bookingRequests');
    }

    return (<>
        <Header />
        {isLoading &&
            <Container className="vh-80 d-flex align-items-center justify-content-center">
                <Row>
                    <div className="text-center mt-32">
                        <img className="loading-img" />
                    </div>
                </Row>
            </Container>}
        {!isLoading &&
            <Container className="mt-5 ml-25">
                <Row>
                    <div className='col-sm-1 mt-3'>
                        <img onClick={backButton} className='back-button-img' />
                    </div>
                    <div className="main-body col-sm-11 p-3">
                        <div className="row">
                            <BookingInfo currentUser={currentUser} />
                        </div>
                    </div>
                </Row>
            </Container>}
    </>);
}

export default BookingRequestDetailsPage;