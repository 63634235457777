import axios from "axios";

export const instance = axios.create({
    baseURL: process.env.REACT_APP_ADMIN_BACKEND_URL,
});

instance.interceptors.request.use(
    function (config) {
        const token = localStorage.getItem('admin-token');
        config.headers.Authorization = token ? `Bearer ${token}` : '';
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    function (res) {
        return res;
    },
    async function (error) {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            try {
                originalRequest._retry = true;
                const user = JSON.parse(localStorage.getItem('user'));
                const response = await axios.post(`${process.env.REACT_APP_ADMIN_BACKEND_URL}/auth/refresh-session`, {
                    email: user.email,
                    refreshToken: user.refreshToken
                });
                localStorage.setItem('admin-token', response?.data?.data?.idToken);
                localStorage.setItem('user', JSON.stringify({
                    email: response?.data?.data?.email,
                    name: response?.data?.data?.name,
                    exp: response?.data?.data?.exp,
                    refreshToken: response?.data?.data?.refreshToken,
                }));
                axios.defaults.headers.common["Authorization"] = `Bearer ${response?.data?.data?.idToken}`;
                return instance(originalRequest);
            } catch (error) {
                localStorage.clear();
                window.location.href = '/login';
            }

        }
        return Promise.reject(error)
    }
)