import React from 'react'
const UserInfo = ({ currentUser }) => {
    return (
        <div className="col-md-8">
            <div className="card d-flex justify-content-center flex-column border rounded shadow p-3 bg-white rounded">
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-3">Full Name</div>
                        <div className="col-sm-9 text-secondary">{currentUser[0]?.name}</div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-sm-3">Email</div>
                        <div className="col-sm-9 text-secondary">{currentUser[0]?.email}</div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-sm-3">Phone</div>
                        <div className="col-sm-9 text-secondary">9878564432</div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-sm-3">Address</div>
                        <div className="col-sm-9 text-secondary">{currentUser[0]?.city}</div>
                    </div>
                </div>
            </div>

            <div className="card d-flex justify-content-center flex-column border rounded shadow p-3 bg-white rounded">
                <div className="card-body text-center">
                    <div className="row">
                        <div className="col-sm-4">Status</div>
                        <div className="col-sm-4">Client Balance</div>
                        <div className="col-sm-4">Rating</div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-sm-4 text-secondary">{currentUser[0]?.status}</div>
                        <div className="col-sm-4 text-secondary">{currentUser[0]?.clientBalance}</div>
                        <div className="col-sm-4 text-secondary">{currentUser[0]?.averageRating}</div>
                    </div>
                </div>
            </div>
            {
                currentUser[0]?.userRole === 'Avatar' ?
                    <div className="card d-flex justify-content-center flex-column border rounded shadow p-2 bg-white rounded">
                        <div className="card-body text-center">
                            <div className="row">
                                <div className="col-sm-4">Charges</div>
                                <div className="col-sm-4">Availability Area</div>
                                <div className="col-sm-4">Availability TIme</div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-sm-4 text-secondary">{currentUser[0]?.charges.amount} {currentUser[0]?.charges.currency} / {currentUser[0]?.charges.unit}</div>
                                <div className="col-sm-4 text-secondary">{currentUser[0]?.availabilityArea.range} / {currentUser[0]?.availabilityArea.unit}</div>
                                <div className="col-sm-4 text-secondary">{currentUser[0]?.availabilityTime.startTime} - {currentUser[0]?.availabilityTime.endTime}</div>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </div>
    );
}

export default UserInfo;