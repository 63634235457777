/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import Clients from "views/Clients";
import Avatars from "views/Avatars";
import BookingRequests from "views/BookingRequests";
import Wallet from "views/Wallet";

const dashboardRoutes = [
  {
    path: "/clients",
    name: "Clients",
    icon: "nc-icon nc-notes",
    component: Clients,
    layout: "/admin"
  },
  {
    path: "/avatars",
    name: "Avatars",
    icon: "nc-icon nc-paper-2",
    component: Avatars,
    layout: "/admin"
  },
  {
    path: "/bookingRequests",
    name: "Booking Requests",
    icon: "nc-icon nc-send",
    component: BookingRequests,
    layout: "/admin"
  },
  {
    path: "/wallet",
    name: "Wallet",
    icon: "nc-icon nc-money-coins",
    component: Wallet,
    layout: "/admin"
  },
];

export default dashboardRoutes;
