import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";
import useClientApi from "hooks/useClientApi";
import TableList from "./TableList";
import Pagination from "./Pagination";
import CustomModal from "./CustomModal";
import { limit } from "../constants";
import { pageCount } from "../constants";
import '../assets/css/demo.css';

const Clients = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const url = `/admin/clients?page=${currentPage}&limit=${limit}`;
    const { client, isLoading, handleDelete, showDeleteModal, setShowDeleteModal, handleConfirmDelete } = useClientApi(url);

    return (<>
        {isLoading &&
            <Container className="vh-80 d-flex align-items-center justify-content-center">
                <Row>
                    <div className="text-center mt-32">
                        <img className="loading-img" />
                    </div>
                </Row>
            </Container>}

        {!isLoading && (
            <Container fluid>
                <TableList tableData={client.clients} tableName={"Clients"} handleDelete={handleDelete}/>
                <CustomModal showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} handleConfirmDelete={handleConfirmDelete} />
            </Container>
        )}

        {!isLoading && client.totalClients > 10 && <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={pageCount(client.totalClients)} />}
    
    </>)
}

export default Clients;