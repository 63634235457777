import React, { useEffect, useState } from "react";
import { instance } from "config/axiosInstance";

const useAvatarDetailsApi = (url) => {
    const [isLoading, setIsLoading] = useState(false)
    const [currentUser, setCurrentUser] = useState({});

    useEffect(() => {
        async function getAvatarDetails() {
            try {
                setIsLoading(true);
                const req = await instance.get(url);
                const { avatars } = req?.data?.data;
                if (req.status === 200) {
                    setCurrentUser(avatars)
                    setIsLoading(false)
                }
            } catch (error) {
                console.log(error);
                setIsLoading(false)
            }
        }
        getAvatarDetails()
    }, [])

    return { currentUser, isLoading };
}

export default useAvatarDetailsApi;