import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Col, Button, Row, Container, Card, Form, InputGroup } from "react-bootstrap";
import { instance } from "config/axiosInstance";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import '../../assets/css/demo.css';
import LoadingImg from "../../assets/img/loading-bubbles.svg";


export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const history = useHistory();

  if (localStorage.getItem('admin-token')) {
    history.push("/admin/clients")
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const credential = {
      email: email,
      password: password,
      userRole: "Admin",
    };

    try {
      const res = await instance.post(`/auth/admin/login`, credential);
      const adminToken = res?.data?.data?.idToken;
      if (!adminToken) {
        alert('Unable to login. Please try after some time.');
        return;
      }
      if (res.status === 200) {
        localStorage.setItem('admin-token', adminToken);
        localStorage.setItem('user', JSON.stringify({
          email: res.data.data.email,
          name: res.data.data.name,
          exp: res.data.data.exp,
          refreshToken: res.data.data.refreshToken,
        }));
        setTimeout(() => {
          setIsLoading(false)
          history.push("/admin/clients")
        }, 500);
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error)
      if (error?.response?.status === 400) {
        alert("Please try again. Invalid email or password.")
        setEmail("")
        setPassword("")
      }
      if (error?.response.status === 404) {
        alert("User not found")
        setEmail("")
        setPassword("")
      }
    }
  };


  return (
    <div>
      {isLoading &&
        <Container className="vh-100 d-flex align-items-center justify-content-center">
          <Row>
            <div className="text-center mt-32">
              <img className="loading-img" />
            </div>
          </Row>
        </Container>
      }
      {!isLoading && <Container>
        <Row className="vh-100 d-flex justify-content-center align-items-center">
          <Col md={8} lg={6} xs={12}>
            <div className="border border-3 border-primary"></div>
            <Card className="shadow">
              <Card.Body>
                <div className="mb-3 mt-md-4">
                  <h2 className="fw-bold mb-2 text-uppercase ">Avatar Admin</h2>
                  <p className=" mb-5">Please enter your Email and password!</p>
                  <div className="mb-3">
                    <Form onSubmit={onSubmit}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="text-center">
                          Email address
                        </Form.Label>
                        <Form.Control type="email" placeholder="Enter email" onChange={e => setEmail(e.target.value)} value={email} required />
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Password</Form.Label>
                        <Form.Group className="password-formgrp outline-secondary">

                          <Form.Control className="pswd-formcontrol" type={passwordShown ? "text" : "password"} placeholder="Password" onChange={e => setPassword(e.target.value)} value={password} required />
                          <span className="eye-btn" onClick={() => { setPasswordShown(!passwordShown) }}>
                            {passwordShown ? <FaEyeSlash /> : <FaEye />}
                          </span>
                        </Form.Group>

                      </Form.Group>
                      <div className="d-grid">
                        <Button variant="primary" type="submit">
                          Login
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>}
    </div>
  );
}