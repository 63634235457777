import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";
import useBookingRequests from "hooks/useBookingRequestsApi";
import TableList from "./TableList";
import LoadingImg from "../assets/img/loading-bubbles.svg"
import Pagination from "./Pagination";
import { limit } from "../constants";
import { pageCount } from "../constants";

const BookingRequests = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const url = `/admin/bookings?page=${currentPage}&limit=${limit}`;
    const { bookingRequest, isLoading } = useBookingRequests(url);

    return (<>
        {isLoading &&
            <Container className="vh-80 d-flex align-items-center justify-content-center">
                <Row>
                    <div className="text-center mt-32">
                        <img className="loading-img" />
                    </div>
                </Row>
            </Container>}
        {!isLoading && <TableList tableData={bookingRequest.bookingRequests} tableName={"Booking Requests"} />}
        {!isLoading && bookingRequest.totalBookingRequests > 10 && <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={pageCount(bookingRequest.totalBookingRequests)} />}
    </>)
}

export default BookingRequests;