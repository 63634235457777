import React from 'react'
import profilePic from '../assets/img/profilePic.png';
const UserProfile = ({ currentUser }) => {
    return (
        <div className="col-md-4">
            <div className="card d-flex justify-content-center flex-column border rounded shadow p-3 bg-white rounded">
                <div className="card-body">
                    <div className="d-flex flex-column align-items-center text-center">
                        <div className='mt-2'>
                            <img src={currentUser[0]?.profilePic ? currentUser[0]?.profilePic : profilePic} className="rounded-circle" width={'160px'} height={'160px'} />
                        </div>
                        <div className="mt-4">
                            <h3>{currentUser[0]?.name}</h3>
                            <h4 className="text-secondary">{currentUser[0]?.userRole}</h4>
                        </div>
                    </div>
                </div>
            </div>
            {currentUser[0]?.description && <div className="card d-flex justify-content-center flex-column border rounded shadow p-3 bg-white rounded">
                <div className="card-body">
                    <h5>About</h5>
                    <hr />
                    <h4 className="text-secondary">{currentUser[0]?.description}</h4>
                </div>
            </div>}
        </div>
    );
}

export default UserProfile;