import React from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import ProtectedRoute from "utils/protectedRoute";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import AdminLayout from "layouts/Admin.js";
import Login from "components/Login/Login";
import AvatarDetailsPage from "views/AvatarDetailsPage";
import ClientDetailsPage from "views/ClientDetailsPage";
import Wallet from "views/Wallet";
import { Toaster } from "react-hot-toast";
import BookingRequestDetailsPage from "views/BookingRequestDetails";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Toaster position="top-center" toastOptions={{ style: { padding: '16px' } }} />
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/avatardetailspage/:id" component={AvatarDetailsPage} />
      <Route path="/clientdetailspage/:id" component={ClientDetailsPage} />
      <Route path="/bookingrequestdetailspage/:id" component={BookingRequestDetailsPage} />
      <Route path="/wallet" component={Wallet} />
      <Route path="/admin" render={(props) => <ProtectedRoute>
        <AdminLayout {...props} />
      </ProtectedRoute>} />
      <Redirect from="/" to="/login" />
    </Switch>
  </BrowserRouter>

);
