import React, { useEffect, useState } from "react";
import { instance } from "config/axiosInstance";

const useBookingRequests = (url) => {

    const [bookingRequest, setBookingRequest] = useState({
        bookingRequests: [],
        totalBookingRequests: null,
    });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function getBookingRequestsListData() {
            try {
                setIsLoading(true);
                const res = await instance.get(url);
                const { bookings, totalBookings } = res?.data?.data;
                if (res.status === 200) {
                    setBookingRequest({
                        bookingRequests: bookings,
                        totalBookingRequests: totalBookings
                    })
                    setIsLoading(false)
                }
            } catch (error) {
                console.log("error", error)
                setIsLoading(false);
            }
        }
        getBookingRequestsListData()
    }, [url])

    return { bookingRequest, isLoading };
}

export default useBookingRequests;