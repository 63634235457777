import React from "react";
import { Modal, Button } from "react-bootstrap";

const CustomModal = ({showDeleteModal, setShowDeleteModal, handleConfirmDelete}) => {
    return (
        <>
        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Body>
           <div className="d-flex justify-content-between">
           <div>
                Delete User
            </div>
            <div>
                <img onClick={() => { setShowDeleteModal(false) }} className='close-button-img' />
            </div>
           </div>
            <h5>
                Are you sure you want to delete the user?
            </h5>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                Cancel
            </Button>
            <Button className="border border-light bg-secondary text-white" onClick={handleConfirmDelete}>
                Delete
            </Button>
        </Modal.Footer>
    </Modal>
    </>
    );
}
export default CustomModal;