import React, { useState } from "react";
import { Button, Container, Form, Modal, Row } from "react-bootstrap";
import useWalletApi from "hooks/useWalletApi";
import TableList from "./TableList";
import LoadingImg from "../assets/img/loading-bubbles.svg";
import Pagination from "./Pagination";
import { limit } from "../constants";
import { pageCount } from "../constants";

const Wallet = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const clientUrl = `/admin/clients?page=${currentPage}&limit=${limit}`;
    const walletUrl = `/admin/clients/wallet`;
    const { client, isLoading, walletDetails, show, setShow, onSubmit, handleChange, handleClick } = useWalletApi(clientUrl, walletUrl);

    return (<>
        {isLoading &&
            <Container className="vh-80 d-flex align-items-center justify-content-center">
                <Row>
                    <div className="text-center mt-32">
                        <img className="loading-img" />
                    </div>
                </Row>
            </Container>}
        {!isLoading && <Container fluid>
            <Modal show={show} onHide={() => { setShow(false) }}>
                <Modal.Body>
                    <div className='d-flex justify-content-end'>
                        <img onClick={() => { setShow(false) }} className='close-button-img' />
                    </div>
                    <Form onSubmit={onSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label className="text-center">
                                Name
                            </Form.Label>
                            <Form.Control type="text" placeholder="Enter name" name="name" value={walletDetails.name} readOnly required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label className="text-center">
                                Email address
                            </Form.Label>
                            <Form.Control type="email" placeholder="Enter email" name="email" value={walletDetails.email} readOnly required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label className="text-center">
                                Wallet Balance
                            </Form.Label>
                            <Form.Control type="number" placeholder="Enter clientBalance" name="clientBalance" value={walletDetails.clientBalance} onChange={handleChange} required />
                        </Form.Group>
                        <div className="d-flex justify-content-center">
                            <Button type="submit" variant="secondary" className="bg-secondary text-white p-2 w-100">Add To Wallet</Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </Container>}
        {!isLoading && < TableList tableData={client.clients} tableName={"Wallet"} handleClick={handleClick} />}
        {!isLoading && client.totalClients > 10 && <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={pageCount(client.totalClients)} />}
    </>);
}
export default Wallet;