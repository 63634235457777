import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";
import useAvatarApi from "hooks/useAvatarApi";
import TableList from "./TableList";
import Pagination from "./Pagination";
import CustomModal from "./CustomModal";
import { limit } from "../constants";
import { pageCount } from "../constants";
import '../assets/css/demo.css';

const Avatars = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const url = `/admin/avatars?page=${currentPage}&limit=${limit}`;
    const { avatar, isLoading, handleDelete, showDeleteModal, setShowDeleteModal, handleConfirmDelete } = useAvatarApi(url);

    return (<>
        {isLoading &&
            <Container className="vh-80 d-flex align-items-center justify-content-center">
                <Row>
                    <div className="text-center mt-32">
                        <img className="loading-img" />
                    </div>
                </Row>
            </Container>}

        {!isLoading && (
            <Container fluid>
                <TableList tableData={avatar.avatars} tableName={"Avatars"} handleDelete={handleDelete} />
                <CustomModal showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} handleConfirmDelete={handleConfirmDelete} />
            </Container>
        )}

        {!isLoading && avatar.totalAvatars > 10 && <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={pageCount(avatar.totalAvatars)} />}

    </>)
}

export default Avatars;