import React from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  Table,
  Container,
  Row,
  Col,
  Button
} from "react-bootstrap";
import { tableHeaderList } from "../constants";
import profilePic from '../assets/img/profilePic.png';


const TableList = ({ tableData, tableName, handleClick, handleDelete }) => {
  const history = useHistory();
  const getDetailsPage = (id) => {
    if (tableName === 'Avatars') {
      history.push(`/avatardetailspage/${id}`);
    }
    else if (tableName === 'Clients') {
      history.push(`/clientdetailspage/${id}`);
    }
    else if (tableName === 'Booking Requests') {
      history.push(`/bookingrequestdetailspage/${id}`);
    }
  }


  const getTableName = (tableTitle) => {
    switch (tableTitle) {
      case "Clients":
        return <Table className="table-hover table-striped">
          <thead>
            {
              <tr>
                {tableHeaderList[0].Clients.map((head, headID) =>
                  <th key={headID}>{head}</th>)}
              </tr>
            }
          </thead>
          <tbody>
            {
              tableData.length > 0 && tableData.map((table) => {
                return (
                  <tr key={table._id} >
                    <td onClick={() => { getDetailsPage(table._id) }}><img src={table.profilePic ? table.profilePic : profilePic} className="rounded-circle cursor-pointer" width={'40px'} height={'40px'} /></td>
                    <td>{table.name}</td>
                    <td> {table.email}</td>
                    <td>{new Date(table.createdAt).getDate()} {new Date(table.createdAt).toLocaleString('default', { month: 'long' })}</td>
                    <td className="text-ellipsed">{table.city}</td>
                    <td><div className="d-flex justify-content-center"><Button type="submit" variant="secondary" className="bg-secondary text-white p-2 w-100" onClick={() => { handleDelete(table._id) }}>Delete</Button></div></td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>

      case "Avatars":
        return <Table className="table-hover table-striped">
          <thead>
            {
              <tr>
                {tableHeaderList[1].Avatars.map((head, headID) =>
                  <th key={headID}>{head}</th>)}
              </tr>
            }
          </thead>
          <tbody>
            {
              tableData.length > 0 && tableData.map((table) => {
                return (
                  <tr key={table._id} >
                    <td onClick={() => { getDetailsPage(table._id) }}><img src={table.profilePic ? table.profilePic : profilePic} className="rounded-circle" width={'40px'} height={'40px'} /></td>
                    <td>{table.name}</td>
                    <td>{table.email}</td>
                    <td>{new Date(table.createdAt).getDate()} {new Date(table.createdAt).toLocaleString('default', { month: 'long' })}</td>
                    <td className="text-ellipsed">{table.city}</td>
                    <td> <div className="d-flex justify-content-center"><Button type="submit" variant="secondary" className="bg-secondary text-white p-2 w-100" onClick={() => { handleDelete(table._id) }}>Delete</Button></div></td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>

      case "Booking Requests":
        return <Table className="table-hover table-striped">
          <thead>
            {
              <tr>
                {tableHeaderList[2].BookingRequests.map((head, headID) =>
                  <th key={headID}>{head}</th>)}
              </tr>
            }
          </thead>
          <tbody>
            {
              tableData.length > 0 && tableData.map((table) => {
                return (
                  <tr key={table._id} onClick={() => { getDetailsPage(table._id) }}>
                    <td>{table.avatarDetails[0].name}</td>
                    <td>{table.clientDetails[0].name}</td>
                    <td>{table.status}</td>
                    <td>{table.duration} {table.durationUnit}</td>
                    <td>{table.charges[0].amount} / {table.charges[0].unit} {table.charges[0].currency}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>


      case "Wallet":
        return <Table className="table-hover table-striped">
          <thead>
            {
              <tr>
                {tableHeaderList[3].Wallet.map((head, headID) =>
                  <th key={headID}>{head}</th>)}
              </tr>
            }
          </thead>
          <tbody>
            {
              tableData.length > 0 && tableData.map((table) => {
                return (
                  <tr key={table._id}>
                    <td>{table.name}</td>
                    <td>{table.email}</td>
                    <td>{table.clientBalance}</td>
                    <td><Button variant="secondary" className="bg-secondary text-white p-2" onClick={() => { handleClick(table.name, table.email, table.clientBalance) }}>Add Money</Button></td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>

      default:
        break;
    }
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">{tableName}</Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                {getTableName(tableName)}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default TableList;
