import React from 'react'
const BookingInfo = ({ currentUser }) => {

    return (
        <>
            <div className="col-md-6 h-100">
                <div className="card d-flex justify-content-center flex-column border rounded shadow p-4 bg-white rounded">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-4">Status</div>
                            <div className="col-sm-8 text-secondary">{currentUser[0]?.status}</div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-4">Created At</div>
                            <div className="col-sm-8 text-secondary">{currentUser[0]?.createdAt.replace('T', ' ').slice(0,19)} (UTC)</div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-4">Charges</div>
                            <div className="col-sm-8 text-secondary">{currentUser[0]?.charges.amount} {currentUser[0]?.charges.currency} / {currentUser[0]?.charges.unit}</div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-4">Duration</div>
                            <div className="col-sm-8 text-secondary">{currentUser[0]?.duration} {currentUser[0]?.durationUnit}</div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-4">Rating</div>
                            <div className="col-sm-8 text-secondary">{currentUser[0]?.rating}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6 h-100">
                <div className="card d-flex justify-content-center flex-column border rounded shadow p-4 bg-white rounded">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-5">Start Time</div>
                            {currentUser[0]?.startTime && (
                                <div className="col-sm-7 text-secondary">{currentUser[0]?.startTime.replace('T', ' ').slice(0,19)} (UTC)</div>
                            )}
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-5">Stream Start Time</div>
                            {currentUser[0]?.streamStartTime && (
                                <div className="col-sm-7 text-secondary">{currentUser[0]?.streamStartTime.replace('T', ' ').slice(0,19)} (UTC)</div>
                            )}
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-5">Stream End Time</div>
                            {currentUser[0]?.streamEndTime && (
                                <div className="col-sm-7 text-secondary">{currentUser[0]?.streamEndTime.replace('T', ' ').slice(0,19)} (UTC)</div>
                            )}
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-5">Total Stream Time</div>
                            {currentUser[0]?.streamDurationTime && (
                            <div className="col-sm-7 text-secondary">{currentUser[0]?.streamDurationTime}</div>
                            )}
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-5">Total Stream Money</div>
                            {currentUser[0]?.streamDurationMoney && (
                            <div className="col-sm-7 text-secondary">{currentUser[0]?.streamDurationMoney} USD</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-12">
                <div className="card d-flex justify-content-center flex-column border rounded shadow p-4 bg-white rounded">
                    <div className="card-body">
                        <div>Feedback</div>
                        <hr />
                        <div className="text-secondary">{currentUser[0]?.feedback}</div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default BookingInfo;