import React, { useEffect, useState } from "react";
import toast from 'react-hot-toast';
import { instance } from "config/axiosInstance";

const useClientApi = (url) => {
  const [client, setClient] = useState({
    clients: [],
    totalClients: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [deleteID, setDeleteId] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const adminDeleteUserUrl = '/admin/delete-account';

  useEffect(() => {
    async function getClientsListData() {
      try {
        setIsLoading(true);
        const res = await instance.get(url);
        const { clients, totalClients } = res?.data?.data;
        if (res.status === 200) {
          setClient({
            clients: clients,
            totalClients: totalClients
          });
          setIsLoading(false);
          setIsDeleted(false);
        }
      } catch (error) {
        console.log("error", error);
        setIsLoading(false);
      }
    }
    getClientsListData();
  }, [url, isDeleted]);

  const handleDelete = async (id) => {
    setDeleteId(id);
    setShowDeleteModal(true);
  }

  const handleConfirmDelete = async () => {
    setIsLoading(true);
    const payload = {
      "userId": deleteID
    };
    try {
      const res = await instance.delete(adminDeleteUserUrl, { data: payload });
      if (res.status === 200) {
        setIsLoading(false);
        setTimeout(() => {
          toast.success("User deleted successfully");
          setIsDeleted(true);
        }, 100);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
    setShowDeleteModal(false);
  };

  return { client, isLoading, handleDelete, showDeleteModal, setShowDeleteModal, handleConfirmDelete };
}

export default useClientApi;