import React from 'react'
import ReactPaginate from "react-paginate";
import '../assets/css/demo.css';

const Pagination = ({currentPage, pageCount, setCurrentPage}) => {

    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1);
    }

    return (
        <ReactPaginate
            className="pagination pagination-container p-2 flex justify-content-center align-items-center"
            containerClassName="pagination justify-content-center"
            pageClassName="page-item m-1"
            pageLinkClassName="font-normal text-base text-dark inline-block  py-2 px-2 flex justify-content-center align-items-center"
            previousClassName="mr-8 leading-none"
            previousLinkClassName="page-link leading-none select-none rounded-circle text-secondary"
            nextClassName="ml-8 leading-none"
            nextLinkClassName="page-link leading-none select-none rounded-circle text-secondary"
            activeClassName="active bg-lightgrey rounded-[7px] flex justify-content-center align-items-center rounded-circle p-1" 
            activeLinkClassName="active text-white"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            breakLabel="..."
            nextLabel=">>>>"
            onPageChange={handlePageClick}
            pageRangeDisplayed={4}
            pageCount={pageCount}
            previousLabel="<<<<"
            renderOnZeroPageCount={null}
            forcePage={currentPage - 1}
        />
    );
}

export default Pagination;