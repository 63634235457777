
export const limit = 10;

export const tableHeaderList = [
    { "Clients": ["Profile", "Name", "Email", "Created At", "City"] },
    { "Avatars": ["Profile", "Name", "Email", "Created At", "City"] },
    { "BookingRequests": ["Clients", "Avatars", "Request Status", "Live stream Duration", "Amount"] },
    { "Wallet": ["Name", "Email", "Wallet Balance"] }
];

export const pageCount = (pageCountValue) => {
    return Math.ceil(pageCountValue / 10);
}

export const getHeaderName = (location) => {
    switch (true) {
        case location.pathname.includes('clients'):
            return 'Clients';
        case location.pathname.includes('avatars'):
            return 'Avatars';
        case location.pathname.includes('bookingRequests'):
            return 'Booking Requests';
        case location.pathname.includes('wallet'):
            return 'Wallet';
        case location.pathname.includes('clientdetailspage'):
            return 'Client Details';
        case location.pathname.includes('avatardetailspage'):
            return 'Avatar Details';
        case location.pathname.includes('bookingrequestdetailspage'):
            return 'Booking Request Details';
        default:
            return '';
    }
};

