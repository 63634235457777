import React, { useState, useEffect } from "react";
import toast from 'react-hot-toast';
import { instance } from "config/axiosInstance";

const useWalletApi = (clientUrl, walletUrl) => {

    const [client, setClient] = useState({
        clients: [],
        totalClients: null,
    });
    const [walletDetails, setWalletDetails] = useState({
        name: "",
        email: "",
        clientBalance: 0,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        async function getClientsListData() {
            try {
                setIsLoading(true);
                const res = await instance.get(clientUrl);
                const { clients, totalClients } = res?.data?.data;
                if (res.status === 200) {
                    setClient({
                        clients: clients,
                        totalClients: totalClients
                    })
                    setIsLoading(false)
                }
            } catch (error) {
                console.log("error", error)
                setIsLoading(false);
            }
        }
        getClientsListData()
    }, [clientUrl])

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const clientDetails = {
            name: walletDetails.name,
            email: walletDetails.email,
            clientBalance: parseInt(walletDetails.clientBalance)
        }

        try {
            const res = await instance.post(walletUrl, clientDetails);
            if (res.status === 200) {
                const updatedResponse = await instance.get(clientUrl);
                const { clients, totalClients } = updatedResponse?.data?.data;
                setIsLoading(false)
                setShow(false)
                setTimeout(() => {
                    toast.success("Wallet Balance updated successfully")
                }, 100)
                if (updatedResponse.status === 200) {
                    setClient({
                        clients: clients,
                        totalClients: totalClients
                    })
                }
            }
        } catch (error) {
            console.log("error", error);
            setIsLoading(false)
        }
    };

    const handleChange = (e) => {
        setWalletDetails({
            ...walletDetails,
            [e.target.name]: e.target.value
        });
    };

    const handleClick = (currentName, currentEmail, currentBalance) => {
        setShow(true)
        setWalletDetails({
            name: currentName,
            email: currentEmail,
            clientBalance: currentBalance
        })
    }

    return { client, isLoading, walletDetails, show, setShow, onSubmit, handleChange, handleClick };
}

export default useWalletApi;